<template>
  <div>
    <!-- PANEL DE CUMPLIMIENTO -->
    <compliance-banner @loadedRequirements="loadedRequirements" :showBanner="false"/>

    <vx-card class="px-1 mb-base">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title">El proyecto semilla.</p>
          <p class="text-2xl mt-1">por {{installerName}}.</p>
        </div>
      </div>
      <div class="vx-row mt-4">
        <div class="vx-col xxl:w-3/4 w-full">
          <p>{{description}}</p>
        </div>
      </div>
      <div class="vx-row mt-6" v-if="canSimulate || canInvest || canShowPaymentsPlan || canAskForRefund">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button v-if="canInvest" id="invest_btn" :data-projectid="projectId" type="border" color="primary" class="sm:px-base px-4 mr-2 mb-3" @click="showInvestment">Invertir</vs-button>
            <vs-button v-if="canSimulate" id="run_simulation_btn" :data-projectid="projectId" type="border" :color="colors.blueBtn" class="sm:px-base px-4 ml-2 mr-2 mb-3" @click="">Simular</vs-button>
            <vs-button v-if="canShowPaymentsPlan" type="border" :color="colors.darkBtn" class="sm:px-base px-4 ml-2 mr-2 mb-3" @click="showPaymentsPlan">Plan de pagos</vs-button>
            <!--vs-button v-if="!isOpportunity && canAskForRefund" :color="colors.darkBtn" class="sm:px-base px-4 ml-2 mb-3" @click="showRefundPopup">¿Quieres mover tus fondos?</vs-button-->
          </div>
        </div>
      </div>
      <div class="vx-row mt-5 mb-1">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <h4>{{progress}}% de avance</h4>
            <p class="ml-6"><span class="qualification-circle-inline" :class="`bg-${qualificationCode(qualification)}`">{{ qualification }}</span></p>
            <p class="text-grey ml-2 hover-link" @click="goToProjectsQualificationBlog">¿Cómo calificamos los proyectos semilla?</p>
          </div>
        </div>
      </div>
    </vx-card>

    <!-- POPUP Reembolso -->
    <vs-popup :active.sync="isRefundPopupActive" title="Reembolso de fondos." v-if="isRefundPopupActive" class="header-primary">
      <refund-project-dialog :project-data="projectData" :project-id="projectId" @on-success="onRefundSuccess" />
    </vs-popup>

    <!-- Popup para tablas -->
    <vs-popup :active.sync="isPopupActive" title="Plan de pagos" class="max-width-popup header-primary" v-if="canShowPaymentsPlan">
      <payments-plan :project-id="projectId" :can-refund="canAskForRefund" @on-refund-click="onRefundClick" />
    </vs-popup>

    <!-- Popup para inversion -->
    <vs-popup class="mid-popup header-primary" :active.sync="isInvestmentPopupActive" title="Nueva inversión" v-if="isInvestmentPopupActive">
      <new-investment-card
        :project-id="projectId"
        :full-process="true"
        :investor-data="investorData"
        :proj-limits-data="projectLimitsData"
        :alias="alias"
        @on-go-to-funding="onGoToFundingRequest"
        @on-cancel="cancelInvestment"
        @on-success="onSuccessInvestment"
        @on-failure="onInvestmentFailed"
      />
    </vs-popup>

    <vs-popup :active.sync="successDialogActive" title="Inversión exitosa." class="header-primary">
      <investment-success-dialog
        :current-project-id="projectId"
        @on-action-1="goToInvestmentPlan"
        @on-action-2="finishInvestment"/>
    </vs-popup>

  </div>
</template>

<script>
import {investorColors} from "@mixins/investorColors";
import currencyHelper from '@mixins/currencyHelper';
import formatHelper from '@mixins/formatHelper';
import qualificationsHelper from "@mixins/qualificationsHelper";
import RefundProjectDialog from "@components/investor/invested/RefundProjectDialog";
import PaymentsPlan from "@components/investor/invested/PaymentsPlan";
import NewInvestmentCard from "@components/investor/opportunityTabs/NewInvestmentCard";
import InvestmentSuccessDialog from "@components/investor/opportunityTabs/InvestmentSuccessDialog";
import {mapState} from "vuex";
import {VMoney} from "v-money";
import ComplianceBanner from "@components/compliance/ComplianceBanner";

export default {
  name: "CreditHeader",
  props: {
    projectId: { type: [String,Number], required: true },
    projectData: { type: Object, required: true },
    canSimulate: { type: Boolean, default: false },
    isOpportunity: { type: Boolean, default: false },
    alias: { type: [String], required: true },
  },
  mixins: [formatHelper, currencyHelper, qualificationsHelper],
  directives: {money: VMoney},
  components: {
    RefundProjectDialog,
    PaymentsPlan,
    NewInvestmentCard,
    InvestmentSuccessDialog,
    ComplianceBanner
  },
  data() {
    return {
      investmentPopupActive: false,
      investmentData: {
        amount: 0,
        availableBonus: 0,
        balanceToUse: 0,
        newBalance: 0
      },
      investmentConfirmationStep: 0,
      mainComplianceRequirements: 0,
      newInvestment: null,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },

      currentProgress: null,

      investorData: {},
      projectLimitsData: {},
      successDialogActive: false
    }
  },
  computed: {
    ...mapState("auth", ["roleGeneralData"]),
    isRefundPopupActive: {
      get() {
        return this.$store.state.isRefundCardPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_REFUND_POPUP_ACTIVE", val);
      }
    },
    isInvestmentPopupActive: {
      get() {
        return this.$store.state.isInvestmentPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_INVESTMENT_POPUP_ACTIVE", val);
      }
    },
    isPopupActive: {
      get() {
        return this.$store.state.isProjectDetailPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE", val);
      }
    },
    colors (){
      return investorColors;
    },
    installerName(){
      return this.projectData.installer.tradename || this.projectData.installer.name;
    },
    description(){
      return this.projectData.project.description;
    },
    progress(){
      return this.currencyFormat(this.currentProgress, 2);
    },
    qualification(){
      return this.projectData.finance.qualification;
    },
    canInvest(){
      return this.projectData.project.status === 5 && this.projectLimitsData.remaining > 0 && this.mainComplianceRequirements === 0;
    },
    canShowPaymentsPlan(){
      return this.projectData.project.status === 5 && this.investorData.projectInvested > 0;
    },
    canAskForRefund(){
      return this.projectData.project.status === 5 && this.investorData.projectInvested > 0;
    }
  },
  async beforeMount() {
    this.setInitialProgress();
    await this.getNewInvestmentData();
  },
  methods: {
    async getNewInvestmentData(){
      try {
        const res = await axios.get(`/api/v2/investor/${this.InvestorId}/getDataForProjectInvestment/${this.projectId}`);
        this.investorData = res.data.investorData;
        this.projectLimitsData = res.data.projectData;
        this.updateProgress(res.data.projectData.currentFunded, res.data.projectData.financedAmount);
      }
      catch (e) {
      }
    },
    async onSuccessInvestment(data){
      this.isInvestmentPopupActive = false;
      this.showLoading(true);
      try{
        await this.onInvestorGeneralDataUpdated();
        await this.getNewInvestmentData();
        this.successDialogActive = true;
      }
      catch (e) {
        this.successDialogWithCallbacks(
          this.goToMyProjects,
          this.finishInvestment,
          '¡Inversión exitosa!',
          'Gracias por ser parte de la Red',
          'Ir a Mis proyectos',
          'Finalizar'
        );
      }
      this.showLoading(false);
    },
    onInvestmentFailed(response){
      this.isInvestmentPopupActive = false;
      if(response && response.data){
        this.errorNotifDialog('La inversión no se ha podido ejecutar', response.data.error);
      }
      else {
        this.errorNotifDialog('Ha ocurrido un error', 'Por favor intenta más tarde');
      }
    },
    async showInvestment(){
      this.newInvestment = null;
      this.isInvestmentPopupActive = true;
    },
    async cancelInvestment(){
      this.isInvestmentPopupActive = false;
    },
    finishInvestment(){
      this.successDialogActive = false;
    },
    async onGoToFundingRequest(){
      this.isInvestmentPopupActive = false;
      await this.goToFunding();
    },
    async onRefundSuccess(){
      try{
        await this.getNewInvestmentData();
      }
      catch (e) {
      }
      this.showLoading(false);
    },
    updateProgress(currentFunded, financedAmount){
      this.currentProgress = (currentFunded / financedAmount) * 100;
    },
    setInitialProgress(){
      this.currentProgress = (this.projectData.project.invested / this.projectData.finance.financed_amount) * 100;
    },
    async goToFunding(){
      await this.$router.push({name: 'abonarFondos'});
    },
    async goToMyProjects(){
      await this.$router.push({name: 'misProyectosInversionista'});
    },
    async goToInvestmentPlan(){
      this.successDialogActive = false;
      await this.$router.push({name: 'planesInversion'});
    },
    showPaymentsPlan(){
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    onRefundClick(){
      this.isPopupActive = false;
      this.isRefundPopupActive = true;
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    },
  }
}
</script>