<template>
    <!-- TABS -->
    <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" v-model="activeTab" class="tabs-shadow-none" id="project-tabs" :key="isSmallerScreen">
      <!-- TAB CREDITO -->
      <vs-tab label="El crédito" icon-pack="feather" icon="icon-file-text">
        <credit-tab :project-data="projectData" />
      </vs-tab>

      <!-- TAB PROYECTO -->
      <vs-tab label="El proyecto" icon-pack="feather" icon="icon-pocket">
        <project-tab :project-data="projectData" :project-id="projectId" />
      </vs-tab>

      <!-- TAB SOLICITANTE -->
      <vs-tab label="El solicitante" icon-pack="feather" icon="icon-user">
        <applicant-tab :project-id="projectId" />
      </vs-tab>

      <!-- TAB INSTALADOR -->
      <vs-tab label="El instalador" icon-pack="feather" icon="icon-shield">
        <installer-tab :project-data="projectData" :project-id="projectId" />
      </vs-tab>

    </vs-tabs>
</template>

<script>
import CreditTab from "@components/investor/opportunityTabs/CreditTab";
import ApplicantTab from "@components/investor/opportunityTabs/ApplicantTab";
import ProjectTab from "@components/investor/opportunityTabs/ProjectTab";
import InstallerTab from "@components/investor/opportunityTabs/InstallerTab";

export default {
  name: "SeedTabs",
  props: {
    projectId: {type: [String,Number], required: true},
    projectData: { type: Object, required: true },
  },
  components: {
    CreditTab,
    ApplicantTab,
    ProjectTab,
    InstallerTab,
  },
  data(){
    return {
      minScreen : 1100,
      activeTab: 0,
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < this.minScreen;
    },
  }
}
</script>