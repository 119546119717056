<template>
  <div>
    <vx-card title="Los contratos.">
      <div class="vx-row">
        <div class="vx-col w-full">
          <p>Consulta los diferentes contratos que pertenecen al proyecto en el que invertiste. Estos documentos son de suma importancia a lo
            largo del plazo del crédito, ya que sustentan tu inversión en caso de que el proyecto se encuentre vencido.</p>
        </div>
      </div>
      <div class="vx-row mt-6" v-if="contracts.length > 0">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button v-for="(contract, idx ) in contracts" type="border" class="sm:px-base px-4 mr-4 mb-3" :key="idx" @click="downloadContract(contract.id)">
              {{contract.name}}
            </vs-button>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="bold mt-5">Los contratos están en proceso de ser firmados, una vez realizado ésto aparecerán en este apartado.</p>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  name: "ProjectContracts",
  props: ['projectId'],
  data(){
    return {
      contracts: [],
      sentDate: null,
      signedDate: null,
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.getContracts();
    this.showLoading(false)
  },
  methods: {
    async getContracts(){
      try {
        const response = await axios.get(`api/inversionista/inversiones/resumen/${this.projectId}/contratos`);
        this.contracts = response.data.contracts;
        this.sentDate = response.data.sended;
        this.signedDate = response.data.signed;
      }
      catch (e) {
      }
    },
    async downloadContract(id){
      let url = `${this.ApiDomain}/api/v2/investor/${this.InvestorId}/viewProjectContract/${id}?access_token=${this.AccessToken}`;
      window.open(url, "_blank");
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId
      };
      this.saveInvestorLogAction("LC10-23",extraParams);
    }
  }
}
</script>

<style scoped>

</style>