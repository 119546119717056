<template>
  <vx-card>
    <div class="vx-row">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">El proyecto.</p>
        <p>
          Consulta y evalúa la información técnica del proyecto, el ahorro que genera, su impacto ambiental y
          otros puntos importantes que te permitirán tomar una decisión final para invertir en un proyecto semilla.
        </p>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">Su tecnología.</p>
      </div>
      <div class="vx-col w-full">
        <vs-table ref="table" stripe noDataText="No se encontraron registros" :data="technologies" class="mt-2">
          <template slot="thead">
            <vs-th class="bold">Producto</vs-th>
            <vs-th class="bold">Cantidad</vs-th>
            <vs-th class="bold">Marca</vs-th>
            <vs-th class="bold">Modelo</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>{{ tr.product }}</vs-td>
              <vs-td>{{ tr.quantity }}</vs-td>
              <vs-td>{{ tr.brand }}</vs-td>
              <vs-td>{{ tr.model }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">Su ahorro y la cobertura.</p>
        <p>La cobertura por ahorro considera el ahorro que el solicitante obtendrá con su proyecto mensualmente
          para pagar el crédito y por ende tu inversión.</p>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3 flex">Ahorro mensual
          <vx-tooltip
            title="Ahorro mensual"
            color="dark"
            position="top"
            text="Ahorro que genera mensualmente el proyecto para el acreditado.">
            <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="h-5 w-5 text-dark" />
          </vx-tooltip>
        </p>
        <p class="info-border-inline mb-6">{{savingFormat}} MXN</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3 flex">Cobertura por ahorro
          <feather-icon class="ml-2 cursor-pointer" @click="goToCoverInfo()" title="Ver artículo" icon="HelpCircleIcon" svgClasses="h-5 w-5 text-dark" />
        </p>
        <p class="info-border-inline mb-6">{{savingCoverFormat}}</p>
      </div>
    </div>

    <div class="vx-row mt-3">
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="card-sub-title mb-1">Su impacto ambiental.</p>
        <p>En la Red además de crecer tu dinero puedes beneficiar al planeta gracias a la energía limpia.
          Consulta cómo impacta cada peso de tu inversión.</p>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3 flex">Inversión de impacto
          <vx-tooltip
            title="Inversión de impacto"
            color="dark"
            position="top"
            text="Cantidad de CO2 evitado por cada peso invertido.">
            <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="h-5 w-5 text-dark" />
          </vx-tooltip>
        </p>
        <p class="info-border-inline mb-6">$1 MXN = {{impactInvestmentFormat}} kgCO<sub>2</sub></p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3 flex">Beneficio ambiental
          <vx-tooltip
            title="Beneficio ambiental"
            color="dark"
            position="top"
            text="Cantidad de CO2 evitado a lo largo de la vida útil del proyecto.">
            <feather-icon class="ml-2 cursor-pointer" icon="HelpCircleIcon" svgClasses="h-5 w-5 text-dark" />
          </vx-tooltip>
        </p>
        <p class="info-border-inline mb-6">{{environmentalImpact}} Ton CO<sub>2</sub></p>
      </div>
    </div>
  </vx-card>
</template>

<script>
import currencyHelper from '@mixins/currencyHelper';
import formatHelper from '@mixins/formatHelper';
export default {
  name: "ProjectTab",
  props: {
    projectId: {type: [String,Number], required: true},
    projectData: { type: Object, required: true },
  },
  mixins: [formatHelper, currencyHelper],
  data(){
    return {
      technologies: [],
    }
  },
  async beforeMount() {
    await this.getTechnolgiesInfo();
  },
  computed: {
    savingFormat(){
      return this.explicitMoneyFormat(this.projectData.project.saving_per_year/12, 0);
    },
    savingCoverFormat(){
      return this.decimalPercentFormat(this.projectData.project.saving_per_year/(this.projectData.finance.monthly_payment *12),2);
    },
    impactInvestmentFormat(){
      return this.currencyFormat(this.projectData.project.investment_environmental_impact, 1);
    },
    environmentalImpact(){
      return this.currencyFormat(this.projectData.project.environmental_impact/1000, 0);
    }
  },
  methods: {
    async goToCoverInfo(){
      window.open('https://blog.redgirasol.com/como-decidir-en-cual-proyecto-semilla-invertir/', "_blank");
    },
    async getTechnolgiesInfo(){
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getProjectTechnologiesInfo/${this.projectId}`);
        this.technologies = res.data;
      }
      catch (e) {
      }
      this.showLoading(false)
    }
  }
}
</script>
