<template>
  <div>
    <!-- BREADCRUMB -->
    <div class="router-header flex flex-wrap items-center mb-6">
      <vx-breadcrumb class="md:ml-6 md:block" v-if="vxBreadcrumb" root="/inversionista" :breadcrumb="vxBreadcrumb" />
    </div>

    <big-title v-if="isMounted">
      <span>{{ alias }}</span>.
    </big-title>

    <vs-tabs class="mt-1" color="primary" ref="tabs" :value="tabIndex">
      <vs-tab label="La semilla" icon="sports_rugby">
        <loan-detail :id="id" :page="'mis-proyectos'"></loan-detail>
      </vs-tab>

      <vs-tab label="El Girasol" icon="local_florist" :disabled="sunflowerDisabled">
        <sunflower v-if="isMounted" :project-data="projectData" :project-id="id" :alias="alias" />
      </vs-tab>

      <vs-tab label="Tu inversión" icon="monetization_on" :disabled="investmentDisabled">
        <investment v-if="isMounted" :project-data="projectData" :project-id="id" :alias="alias" />
      </vs-tab>
    </vs-tabs>

  </div>
</template>

<script>
import CreditHeader from "@components/investor/opportunityTabs/CreditHeader";
import SeedTabs from "@components/investor/opportunityTabs/SeedTabs";
import Sunflower from "@components/investor/invested/Sunflower";
import Investment from "@components/investor/invested/Investment";
import LoanDetail from "@views/investor/funds/LoanDetail.vue"
export default {
  name: "ProjectInvestedDetail",
  props: ["id"],
  components: {
    CreditHeader,
    SeedTabs,
    Sunflower,
    Investment,
    LoanDetail
  },
  data(){
    return {
      alias: null,
      typeName: null,
      projectData: null,
      isMounted: false,
      tabIndex: 0,
      sunflowerDisabled: true,
      investmentDisabled: true,
      vxBreadcrumb: null,
    }
  },
  async mounted(){
    this.isMounted = false
    this.showLoading(true)
    const auth = await this.authorizeViewProject();
    if(!auth){
      await this.$router.replace({name: 'misProyectosInversionista'});
      this.showLoading(false)
      return;
    }
    await this.getProjectInfo();
    this.setDeaultView();
    // seteo de breadcrumb dinamico
    let breadCrumbs = [];
    let home = { title: 'Home' };
    let projects = { title: 'Mis proyectos', url: '/inversionista/mis-proyectos' };
    let detail = { title: this.alias, active:true };
    breadCrumbs.push(home);
    breadCrumbs.push(projects);
    breadCrumbs.push(detail);
    this.vxBreadcrumb = breadCrumbs;
    
    this.isMounted = true
    this.showLoading(false);
  },
  methods: {
    async authorizeViewProject(){
      try {
        await axios.get(`api/v2/investor/${this.InvestorId}/authorizeViewInvestedProject/${this.id}`);
        return true;
      }
      catch (e){
        return false;
      }
    },
    async getProjectInfo(){
      try {
        const res = await axios.get(`api/projects/single/${this.id}`);
        this.projectData = res.data;
        this.alias = this.projectData.project.alias;
        this.typeName = this.projectData.project.name;

        // se genera log de accion
        let extraParams = {
          project_id: this.id,
          alias: this.projectData.project.alias
        };
        this.saveInvestorLogAction("LC10-19", extraParams);
      }
      catch (e){
        this.warn(e);
      }
    },
    setDeaultView(){
      let tab = null;
      this.sunflowerDisabled = this.investmentDisabled = true;
      const status = this.projectData.project.status;
      if(status === 5){
        // en fondeo
        tab = 0;
      }
      else if(status === 6 || status === 7){
        // en contratos || en instalacion
        this.sunflowerDisabled = false;
        tab = 1;
      }
      else if(status === 8){
        // entregado
        this.sunflowerDisabled = this.investmentDisabled = false;
        tab = 2;
      }

      this.$refs.tabs.activeChild(tab, true);
    },
  }
}
</script>
