<template>
  <div>
    <div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h6>Datos personales</h6>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Edad</p>
        <p class="info-border-inline mb-6"> {{safeInfo(general.age)}} </p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Dependientes económicos</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.economic_dependents)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Nivel académico</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.education_level)}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Residencia</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.address)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Actualmente trabajando</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.current_employment)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Ocupación</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.occupation)}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Antigüedad laboral (años)</p>
        <p class="info-border-inline mb-6">{{safeInfo(general.senority_employment)}}</p>
      </div>
    </div>

    <!--div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h6>Historial crediticio</h6>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Score</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Años de historial</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Consultas al buró</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Cuentas abiertas</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
    </div>

    <div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h6>Estado financiero</h6>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Ingresos</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Egresos</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-3/4 w-full" v-if="showFinanceSource">
        <p class="mb-1 pl-3">Principal fuente de financiamiento</p>
        <p class="info-border-inline mb-6">{{ creditSource }}</p>
      </div>
    </div-->
  </div>
</template>

<script>
export default {
  name: "PersonalInfoSection",
  props: {
    info: {type: Object, required: true},
    showFinanceSource: {type: Boolean, default: false},
  },
  computed: {
    general(){
      return this.info.personal || {};
    },
    creditHistory(){
      return this.info.creditHistory || {};
    },
    creditSource(){
      let c = this.creditHistory.main_source_for_credit_payment;
      let m = "Ahorro generado por el propio sistema. ";
      if(c !== null){
        m += c;
      }
      return m;
    }
  },
  methods: {
    safeInfo(val){
      return (val === null || val === undefined || val === "" ) ? 'No provisto' : val;
    }
  }
}
</script>
