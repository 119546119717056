<template>
  <div class="pt-1 p-3">

    <div v-if="showMoneyInput">
      <h4>Invertir en proyecto {{currentProjectName}}</h4>
      <div class="vx-row mt-4">
        <div class="vx-col w-full">
          <p class="mt-1 mb-4">
            Para realizar una inversión en el proyecto
            <span class="bold">{{currentProjectName}}</span>,
            ingresa un monto mínimo de
            <span class="bold">{{explicitMoneyFormat(currentMinInvestment)}}</span> o un monto máximo de
            <span class="bold">{{explicitMoneyFormat(currentMaxInvestment)}}</span> en el espacio correspondiente.
          </p>
          <label class="pl-1">Ingresa un monto:</label>
          <div class="flex items-center flex-grow justify-left mt-1">
            <vs-input
              class="investment-input"
              type="text"
              v-model.lazy="newInvestment"
              v-money="newInvestment !== null || newInvestment === 0 || newInvestment === '$0' ? money : null"
              name="newInvestment"
              v-validate="'required'"
              :min="1"
              :placeholder="minPlaceholder"
              autocomplete="off"/>
            <vs-button class="statement-btn ml-6" color="primary" @click="startInvestment" >Continuar</vs-button>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmar inversion -->
    <div v-if="showConfirmationView">
      <h4 class="mb-2">¡Ya estás a un clic de invertir con impacto!</h4>
      <p>Confirma tu inversión y disfruta de los beneficios de la energía limpia.</p>

      <div class="vx-row mt-5">
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
          <p class="mb-1 pl-1">Nombre del proyecto</p>
          <p class="info-border-inline mb-6">{{currentProjectName}}</p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
          <p class="mb-1 pl-1">Monto de inversión</p>
          <p class="info-border-inline mb-6">{{explicitMoneyFormat(investmentData.amount)}}</p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
          <p class="mb-1 pl-1">Bonos disponibles</p>
          <p class="info-border-inline mb-6">{{explicitMoneyFormat(investmentData.availableBonus)}}</p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="investAllWithBonus">
          <p class="mb-1 pl-1">Bonos restantes</p>
          <p class="info-border-inline mb-6">{{explicitMoneyFormat(investmentData.newBonus)}}</p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="!investAllWithBonus">
          <p class="mb-1 pl-1">Saldo a ser utilizado</p>
          <p class="info-border-inline mb-6">{{explicitMoneyFormat(investmentData.balanceToUse)}}</p>
        </div>
        <div class="vx-col lg:w-1/3 md:w-1/3 sm:w-1/2 w-full" v-if="!investAllWithBonus">
          <p class="mb-1 pl-1">Saldo resultante</p>
          <p class="info-border-inline mb-6">{{explicitMoneyFormat(investmentData.newBalance)}}</p>
        </div>
      </div>
      <div class="flex">
        <vs-button class="mt-4" @click="check2fa">Confirmar</vs-button>
        <vs-button type="border" class="ml-6 mt-4" @click="cancelInvestment">Cancelar</vs-button>
      </div>
    </div>
    <!-- Fin Confirmar inversion -->


    <div v-if="showLoadingView">
      <h3 class="title-primary">En proceso de inversión.</h3>
    </div>

    <div v-if="showNotEnoughBalance">
      <h3 class="title-primary">Saldo insuficiente</h3>
      <p class="mt-4">
        No cuentas con saldo suficiente para realizar la inversión.
      </p>
      <div class="flex mt-3">
        <vs-button class="mt-4" @click="goToFunding">Abonar</vs-button>
      </div>
    </div>

    <div v-if="showInvalidView">
      <h3 class="title-primary">¿Estás list@ para crecer tu dinero?</h3>
      <p class="mt-4">
        Para realizar una inversión en el proyecto
        <span class="bold">{{currentProjectName}}</span>,
        ingresa un monto mínimo de
        <span class="bold">{{explicitMoneyFormat(currentMinInvestment)}}</span> o un monto máximo de
        <span class="bold">{{explicitMoneyFormat(currentMaxInvestment)}}</span> en el espacio correspondiente.
      </p>
      <div class="flex mt-3">
        <vs-button class="mt-4" @click="investmentStep = 0">Volver</vs-button>
      </div>
    </div>

     <!-- Componente para segundo factor -->
     <two-factor-auth
       :user-id="UserId"
       button-id="2fa_activated_btn"
       :current-project-id="projectId"
       custom-indication-message="Por favor ingresa los 6 dígitos de tu Segundo Factor de Autenticación para realizar la inversión."
       @on-code-validated="onAuth"
       @on-code-invalid="onAuthFailed" />

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
  </div>
</template>

<script>
import {VMoney} from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import qualificationsHelper from '@mixins/qualificationsHelper';
import dateHelper from "@mixins/dateHelper";
import TwoFactorAuth from "@components/2fa/TwoFactorAuth";
import auth2faHelper from "@mixins/auth2faHelper";

export default {
  name: 'InvestmentCard',
  mixins: [formatHelper, currencyHelper, qualificationsHelper, dateHelper, auth2faHelper],
  directives: {money: VMoney},
  props: {
    projectId: { type: [String,Number], required: true },
    projLimitsData: { type: Object, required: true },
    investorData: { type: Object, required: true },
    alias: { type: [String], required: true },
    initialInvestment: {type: [String,Number], required: false},
    fullProcess: { type: Boolean, default: false },
  },
  components: {
    TwoFactorAuth
  },
  data() {
    return {
      isMounted: false,
      aliasCurrency: null,
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      currentProjectName: null,
      currentMinInvestment: null,
      currentMaxInvestment: null,
      currentInvestmentAmount: null,
      currentSimulationInvestment: null,
      newInvestment: this.fullProcess ? null : this.initialInvestment,
      minPlaceholder: null,
      investmentStep: 0,

      investAllWithBonus: false,
      investmentData: {
        amount: 0,
        availableBonus: 0,
        balanceToUse: 0,
        newBalance: 0,
        newBonus: 0,
      },

      factorEnabled: false,
    }
  },
  mounted () {
    this.setCurrentProjVars();
  },
  async beforeMount(){
     this.factorEnabled = await this.getUser2FAStatus(this.UserId);
  },
  computed: {
    showMoneyInput(){
      return this.investmentStep === 0;
    },
    showConfirmationView(){
      return this.investmentStep === 1;
    },
    showLoadingView() {
      return this.investmentStep === 2;
    },
    showInvalidView(){
      return this.investmentStep === 90;
    },
    showNotEnoughBalance(){
      return this.investmentStep === 91;
    },
  },
  methods: {
    async startInvestment(){
      this.investmentStep = 0;
      const newInv = this.unMoneyFormat(this.newInvestment);
      const valid = this.validateInvestmentAmount(newInv);
      if(!valid){
        this.investmentStep = 90;
        return;
      }

      // calculo con bonus
      let balance = this.investorData.balance;
      if(this.investorData.bonus > 0){
        balance += this.investorData.bonus;
      }

      if(newInv > balance){
        this.investmentStep = 91;
        return;
      }
      this.setInvestmentVars(newInv);
      this.currentInvestmentAmount = newInv;
      this.investmentStep = 1;
    },
    async goToFunding(){
      this.$emit('on-go-to-funding');
    },
    setInvestmentVars(amount){
      const currentBonus = this.investorData.bonus;
      this.investmentData.amount = amount;
      this.investmentData.availableBonus = currentBonus;
      this.investAllWithBonus = false;

      // establecer bonus
      let balanceToUse = 0;
      let bonusToUse = 0;
      if(currentBonus > 0){
        if(currentBonus >= amount){
          this.investAllWithBonus = true;
          balanceToUse = 0;
          bonusToUse = amount;
        }
        else {
          bonusToUse = currentBonus;
          balanceToUse = amount - bonusToUse;
        }
      }
      else {
        balanceToUse = amount;
      }
      this.investmentData.balanceToUse = balanceToUse;
      this.investmentData.newBalance = this.investorData.balance - balanceToUse;
      this.investmentData.newBonus = currentBonus - bonusToUse;
    },
    async confirmInvestment(){
      let payload = {
        investor_id: this.InvestorId,
        project_id: this.projectId,
        amount: this.investmentData.amount,
        geolocation: this.CurrentGeolocationData
      }
      this.investmentStep = 2;
      this.showLoading(true);
      try {
        const res = await axios.post('/api/v2/investor/createNewInvestment', payload);
        this.showLoading(false);
        this.$emit('on-success', res.data);
      }
      catch (e) {
        this.showLoading(false);
        this.$emit('on-failure', e.response);
      }
    },
    async cancelInvestment(){
      this.$emit('on-cancel');
    },
    setCurrentProjVars(){
      this.currentProjectName = this.alias;
      this.currentMinInvestment = this.projLimitsData.minimalInvestment;
      this.currentMaxInvestment = this.projLimitsData.maxInvestment;
      this.minPlaceholder = `min. ${this.explicitMoneyFormat(this.currentMinInvestment, 0)}`;
    },
    validateInvestmentAmount(amount){
      if(amount === null){
        return false;
      }
      return amount >= this.currentMinInvestment && amount <= this.currentMaxInvestment;
    },
    finishInvestment(){
      // does nothing
    },
    async goToMyProjects(){
      await this.$router.push({name: 'misProyectosInversionista'});
    },
    getProgresColor (num) {
      if (num >= 50)  return 'success'
      if (num < 50 && num >= 20) return 'warning'
      if (num < 20)  return 'danger'
      return 'primary'
    },
    async check2fa(){
      if(!this.factorEnabled){
        await this.cancelInvestment();
        this.$vs.dialog({
          color: "warning",
          type: "confirm",
          title: "Autenticación necesaria",
          text: "Para realizar esta operación es necesario habilitar tu Segundo Factor de Autenticación.",
          acceptText: "Habilitar",
          cancelText: 'Cancelar',
          accept: this.goTo2FaPage,
        });
        return;
      }
      this.request2faCode();
    },
    async goTo2FaPage(){
      await this.$router.push({name: 'auth2FaInversionista'});
    },
    request2faCode(){
      // se pide la geolocalizacion para la operacion
      this.$refs.rgGeolocator.askForGeolocation();
      this.$store.commit("TOGGLE_IS_2FA_POPUP_ACTIVE", true);
    },
    async onAuth(code){
      await this.confirmInvestment();
    },
    async onAuthFailed(code){
    },
  }
}
</script>
