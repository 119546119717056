<template>
  <vx-card title="El solicitante.">
    <div class="vx-row mb-6">
      <div class="vx-col xxl:w-3/4 w-full">
        <p>
          Consulta y evalúa la información más relevante sobre el solicitante.
        </p>
      </div>
    </div>

    <personal-info-section :info="applicantInfo" v-if="isPersonal && isMounted" :show-finance-source="true"/>
    <business-info-section :info="applicantInfo" v-if="!isPersonal && isMounted"/>

  </vx-card>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import PersonalInfoSection from "@components/investor/projects/PersonalInfoSection";
import BusinessInfoSection from "@components/investor/projects/BusinessInfoSection";
export default {
  name: "ApplicantTab",
  props: {
    projectId: {type: [String,Number], required: true},
  },
  mixins: [formatHelper],
  components: {
    PersonalInfoSection,
    BusinessInfoSection
  },
  data(){
    return {
      applicantInfo: null,
      personType: null,
      isPersonal: null,
      isMounted: false,
    }
  },
  async beforeMount() {
    this.isMounted = false
    await this.getApplicantInfo();
    this.isMounted = true
  },
  methods: {
    async getApplicantInfo(){
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getProjectApplicantInfo/${this.projectId}`);
        this.applicantInfo = res.data.info;
        this.personType = res.data.person_type;
        this.isPersonal = res.data.is_personal;
      }
      catch (e) {
      }
      this.showLoading(false)
    },
  }
}
</script>
