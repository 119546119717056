<template>
  <div id="payments-plan-popup">
    <vx-card title="Eventos de la cobranza">
      <vs-table ref="table" :data="events" :max-items="itemsPerPage" stripe pagination noDataText="No hay registros disponibles">
        <div slot="header" class="ml-4 mb-6 flex items-center flex-grow justify-left">
          <span class="ml-2 mr-4">Mostrando {{ currentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ events.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : events.length }} de <strong>{{ events.length }}</strong> </span>

          <!--vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer ml-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center font-medium">
              <span class="mr-2 text-sm">Regs. por página: {{ itemsPerPage}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="updateItemsPerPage(it)" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown-->

        </div>

        <template slot="thead">
          <vs-th class="bold" sort-key="created_at">Fecha</vs-th>
          <vs-th class="bold">Evento</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].created_at">
              {{ humanDateFormat(tr.created_at) }}
            </vs-td>
            <vs-td :data="data[indextr].id">
             <strong>{{tr.title}}</strong><br>
             {{tr.event}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
export default {
  name: "InvestorCashingEventLogs",
  props: {
    projectId: { type: [String,Number], required: true},
  },
  mixins: [dateHelper],
  data(){
    return {
      events: [],
      parameter: [],
      payments: [],
      itemsPerPage: 10,
      itemsPerPageDefinitions: [10,15,20,30,50],
      isMounted: false,
      failed: false,
    }
  },
  async mounted() {
    this.showLoading(true)
    this.isMounted = false
    await this.getEventsInfo();
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    currentPage: {
      get: function(){
        if(this.isMounted){
          return this.$refs.table.currentx;
        }
        return 0;
      },
      set: function (val){
        this.$refs.table.currentx = val;
      }
    },
  },
  methods: {
    async getEventsInfo(){
      try{
        const response = await axios.get(`api/inversionista/inversiones/resumen/${this.$props.projectId}/cashingEventLogs`);
        this.events = response.data;
      }
      catch (e) {
        this.failed = true;
      }
    },
    updateItemsPerPage(it){
      this.itemsPerPage = it;
      this.currentPage = 1;
    },
  }
}
</script>

<style scoped>

</style>