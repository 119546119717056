<template>
  <vx-card>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <div class="flex">
          <p class="card-sub-title">{{installerName}}, el instalador.</p>
          <a
            v-if="webpageUrl"
            :href="$sanitizeUrl(webpageUrl)"
            target="_blank"
            rel="noopener noreferrer">
            <feather-icon icon="GlobeIcon" class="inline-social-icon text-dark" />
          </a>
          <a
            v-if="facebookUrl"
            :href="$sanitizeUrl(facebookUrl)"
            target="_blank"
            rel="noopener noreferrer">
            <feather-icon icon="FacebookIcon" class="inline-social-icon text-dark" />
          </a>
          <a
            v-if="twitterUrl"
            :href="$sanitizeUrl(twitterUrl)"
            target="_blank"
            rel="noopener noreferrer">
            <feather-icon icon="TwitterIcon" class="inline-social-icon text-dark" />
          </a>
          <a
            v-if="instagramUrl"
            :href="$sanitizeUrl(instagramUrl)"
            target="_blank"
            rel="noopener noreferrer">
            <feather-icon icon="InstagramIcon" class="inline-social-icon text-dark" />
          </a>
        </div>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-3/4 w-full">
        <p>
          Consulta y evalúa la información más relevante sobre el instalador, sus proyectos y
          lo que representan en fondeo, así como las evidencias de sus últimas instalaciones.
        </p>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Proyectos en fondeo</p>
        <p class="info-border-inline mb-6">{{projectsFunding}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Proyectos en instalación</p>
        <p class="info-border-inline mb-6">{{projectsInstalling}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Proyectos entregados</p>
        <p class="info-border-inline mb-6">{{projectsDelivered}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/3 w-full">
        <p class="mb-1 pl-3">Monto financiado</p>
        <p class="info-border-inline mb-6">{{financedAmount}} MXN</p>
      </div>
    </div>

    <div class="vx-row mt-base" v-if="carousel.length > 0">
      <div class="vx-col w-full">
        <image-carousel :images="carousel" v-if="carousel.length > 0" />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ImageCarousel from "@components/shared/ImageCarousel";
const REGEX = /(?:\.([^.]+))?$/;
const imageExtensions = ['png', 'jpg', 'jpeg', 'bmp'];
const httpPattern = /^http[s]*:\/\/[\w]+/i;
import formatHelper from "@mixins/formatHelper";
export default {
  name: "InstallerTab",
  props: {
    projectId: {type: [String,Number], required: true},
    projectData: { type: Object, required: true },
  },
  mixins: [formatHelper],
  components: {ImageCarousel},
  data(){
    return {
      projectsInfo: {
        funding: 0,
        installing: 0,
        delivered: 0,
        financedAmount: 0,
      },
      images: [],
      carousel: [],
    }
  },
  async beforeMount() {
    await this.getSupplierInfo();
  },
  methods: {
    async getSupplierInfo(){
      this.showLoading(true)
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getProjectInstallerInfo/${this.projectId}`);
        this.projectsInfo = res.data.projectsInfo;
        this.images = res.data.images;

        // general url
        this.images.forEach(img => {
          if(this.validateImageExtension(img.storage_file)){
            let url = `${this.ApiDomain}/storagev3/documents/view_document_file/${img.id}/project/${img.pid}?access_token=${this.AccessToken}`;
            this.carousel.push({index: img.id, url: url, filename: img.storage_file});
          }
        });
      }
      catch (e) {
      }
      this.showLoading(false)
    },
    validateImageExtension(filename){
      let ext = REGEX.exec(filename)[1];
      let dext = ext.toLowerCase();
      return imageExtensions.includes(dext);
    },
    formatUrl(url){
      if(!url){
        return null;
      }

      if(!httpPattern.exec(url)){
        return "https://" + url;
      }
      else{
        return url;
      }
    }
  },
  computed: {
    installerName(){
      return this.projectData.installer.tradename || this.projectData.installer.name;
    },
    projectsFunding(){
      return this.projectsInfo.funding;
    },
    projectsInstalling(){
      return this.projectsInfo.installing;
    },
    projectsDelivered(){
      return this.projectsInfo.delivered;
    },
    financedAmount(){
      return this.explicitMoneyFormat(this.projectsInfo.financedAmount, 2);
    },
    facebookUrl(){
      return this.formatUrl(this.projectData.installer.facebook);
    },
    webpageUrl(){
      return this.formatUrl(this.projectData.installer.webpage);
    },
    twitterUrl(){
      return this.formatUrl(this.projectData.installer.twitter);
    },
    instagramUrl(){
      return this.formatUrl(this.projectData.installer.instagram);
    }
  },
}
</script>
