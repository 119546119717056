<template>
  <div>
    <div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h5>Información de la empresa</h5>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Nombre</p>
        <p class="info-border-inline mb-6"> {{safeInfo(moral.name)}} </p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Giro comercial</p>
        <p class="info-border-inline mb-6">{{safeInfo(moral.business_activity)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Fecha de constitución</p>
        <p class="info-border-inline mb-6">{{safeInfo(regularDateFormat(moral.constitution_date))}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Dirección de las oficinas principales</p>
        <p class="info-border-inline mb-6">{{safeInfo(moral.address)}}</p>
      </div>
    </div>
    <!--div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h6>Historial crediticio</h6>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Score</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Años de historial</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Consultas al buró</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Cuentas abiertas</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
    </div>

    <div class="vx-row mt-4 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h6>Estado financiero</h6>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Ingresos</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-1/4 lg:w-1/3 md:w-1/3 sm:w-1/2 w-full">
        <p class="mb-1 pl-3">Egresos</p>
        <p class="info-border-inline mb-6">{{safeInfo(null)}}</p>
      </div>
      <div class="vx-col xxl:w-3/4 w-full">
        <p class="mb-1 pl-3">Principal fuente de financiaminto</p>
        <p class="info-border-inline mb-6">{{ moralCreditSource }}</p>
      </div>
    </div-->

    <hr>

    <div class="vx-row mt-6 mb-4">
      <div class="vx-col xxl:w-3/4 w-full">
        <h5>Información del representante legal</h5>
      </div>
    </div>
    <personal-info-section :info="owner" />

  </div>
</template>

<script>
import PersonalInfoSection from "@components/investor/projects/PersonalInfoSection";
import dateHelper from "@mixins/dateHelper";
export default {
  name: "BusinessInfoSection",
  props: {
    info: {type: Object, required: true},
  },
  mixins: [dateHelper],
  components: {
    PersonalInfoSection
  },
  computed: {
    moral(){
      return this.info.moral.business || {};
    },
    moralCreditHistory(){
      return this.info.moral.creditHistory || {};
    },
    owner(){
      return this.info.owner;
    },
    moralCreditSource(){
      let c = this.moralCreditHistory.main_source_for_credit_payment;
      let m = "Ahorro generado por el propio sistema. ";
      if(c !== null){
        m += c;
      }
      return m;
    }
  },
  methods: {
    safeInfo(val){
      return (val === null || val === undefined || val === "" ) ? 'No provisto' : val;
    }
  }
}
</script>
