<template>
  <div class="pt-1 p-4" id="investment_success_container" :data-projectid="currentProjectId">
    <h4 class="mb-2">¡Felicidades!</h4>
    <p>Tu inversión fue realizada con éxito y muy pronto estará generando beneficios para ti
      y para el planeta. En RedGirasol ¡el sol brilla para todos!</p>

    <p class="mt-4 mb-1 bold">¿Ya configuraste la forma en la que inviertes en los proyectos semilla?</p>
    <p>Descubre cómo automatizar tu cuenta y maximizar tus ganancias.</p>

    <div class="flex mt-4">
      <vs-button class="mt-4" @click="action1">Planes de inversión</vs-button>
      <vs-button type="border" class="ml-6 mt-4" @click="action2">Continuar invirtiendo</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestmentSuccessDialog",
  props: {
    currentProjectId: {
      type:[String,Number], 
      required: false,
      default: null
    },
  },
  methods: {
    async action1(){
     await this.$emit('on-action-1');
    },
    async action2(){
      await this.$emit('on-action-2');
    }
  }
}
</script>

<style scoped>

</style>