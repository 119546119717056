<template>
  <div id="payments-plan-popup">
    <vx-card title="">
      <vs-table ref="paymentsPlanTable" :data="plans" :max-items="itemsPerPage" stripe pagination noDataText="No hay registros disponibles">
        <div slot="header" class="ml-4 mb-6 flex items-center flex-grow justify-left">
          <span class="ml-2 mr-4">Mostrando {{ paymentsPlanCurrentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ plans.length - paymentsPlanCurrentPage * itemsPerPage > 0 ? paymentsPlanCurrentPage * itemsPerPage : plans.length }} de <strong>{{ plans.length }}</strong> </span>

          <!--vs-dropdown v-if="isMounted" vs-trigger-click class="cursor-pointer ml-4 items-per-page-handler">
            <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center font-medium">
              <span class="mr-2 text-sm">Regs. por página: {{ itemsPerPage}}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item v-for="it in itemsPerPageDefinitions" @click="updatePlanPaymentsItemsPerPage(it)" :key="it">
                <span>{{it}}</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown-->

        </div>

        <template slot="thead">
          <vs-th class="bold" sort-key="number">No.</vs-th>
          <vs-th class="bold">Día de Pago</vs-th>
          <vs-th class="bold">Pago</vs-th>
          <vs-th class="bold">Saldo Inicial</vs-th>
          <vs-th class="bold">Capital<br>Amortizado</vs-th>
          <vs-th class="bold">Intereses</vs-th>
          <vs-th class="bold">IVA</vs-th>
          <!-- <vs-th class="bold">Pen.</vs-th> -->
          <!-- <vs-th class="bold">Intereses<br>Moratorios</vs-th> -->
          <vs-th class="bold">Comisión<br>RedGirasol</vs-th>
          <vs-th class="bold">Saldo Final</vs-th>
          <vs-th class="bold"></vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].number">
              {{ tr.number }}
            </vs-td>
            <vs-td :data="data[indextr].date">
              {{ humanDateFormat(tr.date) }}
            </vs-td>
            <vs-td :data="data[indextr].payment">
              {{ explicitMoneyFormat(tr.payment) }}
            </vs-td>
            <vs-td :data="data[indextr].first">
              {{ explicitMoneyFormat(tr.first) }}
            </vs-td>
            <vs-td :data="data[indextr].amorti">
              {{ explicitMoneyFormat(tr.amorti) }}
            </vs-td>
            <vs-td :data="data[indextr].interest">
              {{ explicitMoneyFormat(tr.interest) }}
            </vs-td>
            <vs-td :data="data[indextr].iva">
              {{ explicitMoneyFormat(tr.iva) }}
            </vs-td>
            <!-- <vs-td :data="data[indextr].penalty">
              {{ explicitMoneyFormat(tr.penalty) }}
            </vs-td> -->
            <!-- <vs-td :data="data[indextr].moratorium">
              {{ explicitMoneyFormat(tr.moratorium) }}
            </vs-td> -->
            <vs-td :data="data[indextr].investor_comission">
              {{ explicitMoneyFormat(tr.investor_comission) }}
            </vs-td>
            <vs-td :data="data[indextr].final">
              {{ explicitMoneyFormat(tr.final) }}
            </vs-td>
            <vs-td :data="data[indextr].paid">
              {{ tr.paid ? 'Pagado' : '-' }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <div class="vx-row mt-4" v-if="canRefund">
        <div class="vx-col lg:w-3/4 w-full">
          Las fechas del plan de pagos durante la etapa de fondeo son estimados y podrían variar al iniciar
          el proceso de instalación. Para un reembolso de tus fondos, da clic
          <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="onRefundClick">aquí.</router-link></span>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import dateHelper from "@mixins/dateHelper";
import formatHelper from "@mixins/formatHelper";
export default {
  name: "PaymentsPlan",
  props: {
    projectId: { type: [String,Number], required: true},
    canRefund: { type: Boolean, default: false},
  },
  mixins: [dateHelper, formatHelper],
  data(){
    return {
      plans: [],
      parameter: [],
      payments: [],
      itemsPerPage: 10,
      itemsPerPageDefinitions: [10,15,20,30,50],
      isMounted: false,
      failed: false,
    }
  },
  async mounted() {
    this.showLoading(true)
    this.isMounted = false
    await this.getPaymentsInfo();
    this.isMounted = true
    this.showLoading(false)
  },
  computed: {
    paymentsPlanCurrentPage: {
      get: function(){
        if(this.isMounted){
          return this.$refs.paymentsPlanTable.currentx;
        }
        return 0;
      },
      set: function (val){
        this.$refs.paymentsPlanTable.currentx = val;
      }
    },
  },
  methods: {
    async getPaymentsInfo(){
      try{
        const response = await axios.get(`api/inversionista/inversiones/resumen/${this.$props.projectId}/pagos`);
        this.plans = response.data.plans;
        this.payments = response.data.payments;
        this.parameter = response.data.parameter;
      }
      catch (e) {
        this.failed = true;
      }
    },
    updatePlanPaymentsItemsPerPage(it){
      this.itemsPerPage = it;
      this.paymentsPlanCurrentPage = 1;
    },
    async onRefundClick(){
      this.$emit('on-refund-click');
    }
  }
}
</script>

<style scoped>

</style>