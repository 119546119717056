<template>
  <div>
    <vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <p class="card-sub-title mb-3">{{currentStatus}}.</p>
          <p v-if="currentEvent">{{ humanDateFormat(currentEvent.created_at) }}:  {{ currentEvent.event }}</p>
          <p v-else>Consulta más detalles sobre los eventos que han ocurrido en tu proyecto a lo largo del proceso de fondeo e
            instalación al dar clic en el botón "Eventos del proyecto".</p>
        </div>
      </div>
      <div class="vx-row mt-6">
        <div class="vx-col w-full">
          <div class="flex flex-wrap items-center">
            <vs-button color="primary" class="sm:px-base px-4 mr-2 mb-3" @click="showProjectEvents">Eventos del proyecto</vs-button>
            <vs-button type="border" color="dark" class="sm:px-base px-4 ml-2 mb-3" @click="showPaymentsPlan">Plan de pagos</vs-button>
            <vs-button v-if="showCashingEvents" type="border" class="sm:px-base px-4 ml-4 mb-3" @click="showCashingLogEvents">Eventos de la cobranza</vs-button>
          </div>
        </div>
      </div>
    </vx-card>

    <div class="mt-4">
      <project-evidences :project-id="projectId" />
    </div>

    <!-- TODO-DELETE 28/10/2022 -->
    <!-- <div class="mt-4">
      <project-contracts :project-id="projectId" />
    </div> -->

    <!-- Popup para tablas -->
    <vs-popup :active.sync="isPopupActive" :title="alias" class="max-width-popup">
      <payments-plan :project-id="projectId" v-if="currentView === 1" />
      <investor-project-events :project-id="projectId" v-if="currentView === 2" />
      <investor-cashing-event-logs :project-id="projectId" v-if="currentView === 3" />
    </vs-popup>

  </div>
</template>

<script>
import projectStatusesHelper from "@mixins/projectStatusesHelper";
import creditStatusesHelper from "@mixins/creditStatusesHelper";
import PaymentsPlan from "@components/investor/invested/PaymentsPlan";
import InvestorProjectEvents from "@components/investor/invested/InvestorProjectEvents";
import ProjectEvidences from "@components/investor/invested/ProjectEvidences";
import ProjectContracts from "@components/investor/invested/ProjectContracts";
import InvestorCashingEventLogs from "@components/investor/invested/InvestorCashingEventLogs";
import dateHelper from "@mixins/dateHelper";
import AssetStatusEnum from "@/modules/enums/AssetStatusEnum";
export default {
  name: "Sunflower",
  props: {
    projectData: { type: Object, required: true },
    projectId: { type: [String,Number], required: true },
    alias: { type: [String], required: true },
  },
  data(){
    return {
      currentView: 0,
      events: [],
    }
  },
  components: {
    PaymentsPlan,
    InvestorProjectEvents,
    ProjectEvidences,
    ProjectContracts,
    InvestorCashingEventLogs
  },
  mixins: [projectStatusesHelper, creditStatusesHelper, dateHelper, AssetStatusEnum],
  computed: {
    currentStatus(){
      if(this.projectData.project.status === 7) {
        let nn = this.projectStatusName(this.projectData.project.status);
        nn += ". Pago: ";
        nn += this.creditStatusName(this.projectData.finance.asset_status, this.projectData.finance.credit_status);
        return nn;
      }
      else {
        return this.projectStatusName(this.projectData.project.status);
      }
    },
    currentEvent(){
      if(this.events.length && this.events.length > 0){
        return this.events[0];
      }
      else{
        return null;
      }
    },
    currentDescription(){
      if(this.events.length && this.events.length > 0){
        return this.events[0].event;
      }
      else{
        return null;
      }
    },
    showEvicences(){
      return this.projectData.project.status === 8;
    },
    isPopupActive: {
      get() {
        return this.$store.state.isProjectDetailPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE", val);
      }
    },
    showCashingEvents(){
      let validStatuses = [5,6,7];
      let validAssetStatuses = [AssetStatusEnum.VIGENTE,AssetStatusEnum.PAGADO];
      let show= false;
      if (validStatuses.includes(this.projectData.project.status) && validAssetStatuses.includes(this.projectData.finance.asset_status)){
        show=true;
      }
      return show;
    },
  },
  async mounted(){
    this.showLoading(true)
    await this.getEventsInfo();
    this.showLoading(false)
  },
  methods: {
    showPaymentsPlan(){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId,
        alias: this.alias
      };
      this.saveInvestorLogAction("LC10-21", extraParams);
      this.currentView = 1;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showProjectEvents(){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId,
        alias: this.alias
      };
      this.saveInvestorLogAction("LC10-22", extraParams);
      this.currentView = 2;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    showCashingLogEvents(){
      // se genera log de accion
      let extraParams = {
        project_id: this.projectId,
        alias: this.alias
      };
      this.saveInvestorLogAction("LC10-20", extraParams);
      this.currentView = 3;
      this.$store.commit('TOGGLE_IS_PROJECT_DETAIL_POPUP_ACTIVE', true)
    },
    backToDetail(){
      this.currentView = 0;
    },
    async getEventsInfo(){
      try{
        const response = await axios.get(`api/inversionista/inversiones/resumen/${this.$props.projectId}/bitacora`);
        this.events = response.data;
      }
      catch (e) {
      }
    },
  }
}
</script>

<style scoped>

</style>